import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar'
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import Breadcrumb from 'primevue/breadcrumb';
import TabMenu from 'primevue/tabmenu';
import PanelMenu from 'primevue/panelmenu';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import FileUpload from 'primevue/fileupload';
import MultiSelect from 'primevue/multiselect';
import PickList from 'primevue/picklist';
import Toolbar from 'primevue/toolbar';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import RadioButton from 'primevue/radiobutton';

//#region table 관련 import
import TreeTable from 'primevue/treetable';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
//#endregion

//#region dialog 관련 import
import Dialog from 'primevue/dialog';
//#endregion

// 엑셀 임포트
import * as XLSX from 'xlsx';

//#region 프라임뷰 관련 스타일 파일 선언
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.min.css';
import 'primeicons/primeicons.css';
//#endregion

import '@/assets/css/all.min.css';
import '@/assets/css/admin_common.css';
import '@/assets/css/admin_css.css';

import "@/scripts/prototype/custom-array-prototype";
import "@/scripts/prototype/custom-number-prototype";
import "@/scripts/prototype/custom-string-prototype";

const app = createApp(App);

app.use(store);
app.use(router);

// 프라임뷰 설정
app.use(PrimeVue, {
    locale: {
        //#region default
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        choose: '첨부',
        upload: '업로드',
        cancel: '취소',
        chooseYear: 'Choose Year',
        chooseMonth: 'Choose Month',
        chooseDate: 'Choose Date',
        prevDecade: 'Previous Decade',
        nextDecade: 'Next Decade',
        prevYear: 'Previous Year',
        nextYear: 'Next Year',
        prevMonth: 'Previous Month',
        nextMonth: 'Next Month',
        prevHour: 'Previous Hour',
        nextHour: 'Next Hour',
        prevMinute: 'Previous Minute',
        nextMinute: 'Next Minute',
        prevSecond: 'Previous Second',
        nextSecond: 'Next Second',
        weekHeader: 'Wk',
        firstDayOfWeek: 0,
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
        searchMessage: '{0} results are available',
        selectionMessage: '{0} items selected',
        emptySelectionMessage: 'No selected item',
        emptySearchMessage: 'No results found',
        emptyMessage: 'No available options',
        aria: {
            trueLabel: 'True',
            falseLabel: 'False',
            nullLabel: 'Not Selected',
            star: '1 star',
            stars: '{star} stars',
            selectAll: 'All items selected',
            unselectAll: 'All items unselected',
            close: 'Close',
            previous: 'Previous',
            next: 'Next'
        },
        //#endregion

        accept: 'Yes',
        reject: 'No',
        dayNames: ["일", "월", "화", "수", "목", "금", "토"],
        dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
        //캘린더에 표시되는 요일 
        dayNamesMin: ["일", "월", "화", "수", "목", "금", "토"],
        //캘린더에 표시되는 월 
        monthNames: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        //월 선택시 표시되는 월
        monthNamesShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        clear: '닫기',
        am: '오전',
        pm: '오후',
        today: '오늘',
        dateFormat: 'yy-mm-dd',
        apply: '적용',
        startsWith: '시작되는',
        contains: '포함',
        notContains: '제외',
        endsWith: '끝나는',
        equals: '같은',
        notEquals: '다른',
    }
});

//#region 사용할 프라임 뷰 컴포넌트들 선언 (전역적으로 사용가능)

//#datatable
app.component("DataTable", DataTable);
app.component("TreeTable", TreeTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
//#enddatatable

app.component("Dialog", Dialog)
app.component('Button', Button);
app.component('InputText', InputText);
app.component('Textarea', Textarea);
app.component('Dropdown', Dropdown);
app.component("Calendar", Calendar);
app.component("ProgressSpinner", ProgressSpinner);
app.component("ProgressBar", ProgressBar);
app.component("Breadcrumb", Breadcrumb);
app.component("TabMenu", TabMenu);
app.component("PanelMenu", PanelMenu);
app.component("Splitter", Splitter);
app.component("SplitterPanel", SplitterPanel);
app.component("FileUpload", FileUpload);
app.component("MultiSelect", MultiSelect);
app.component("PickList", PickList);
app.component("Toolbar", Toolbar);
app.component("Checkbox", Checkbox);
app.component("Password", Password);
app.component("RadioButton", RadioButton);

// 엑셀 컴포넌트
app.component('XLSX', XLSX) // FileUpload 컴포넌트

app.directive('slow', (trgt, data) => {
    // [check-this] cumstom directive for loading bar... 이렇게 까지 해야 할까???
    if (data.value)
        trgt.style.display = '';
    else
        setTimeout(function () { trgt.style.display = 'none'; }, 500);
});

app.mount("#app");