<template>
    <router-view />
</template>
<script>
export default {

    setup() { },
}
</script>
<style>
    /* sweetalert2 Index property */
    .swal2-container {
        z-index: 20000 !important;
    }
    .a-hover a:hover {color:#1d4ed8; font-weight:600;}
    .text-overflow1 { 
        width: 700px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .text-overflow2 { 
        width: 900px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
</style>