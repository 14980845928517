import { createRouter, createWebHistory } from "vue-router";

/**
 * 라우터 동작 전 호출되는 함수
 * @param {*} to : 이동할 Url
 * @param {*} from : 현재 Url
 * @param {*} next : to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
 */
const beforeEnter = (to, from, next) => {

    // 인증여부
    const isLogin = !!sessionStorage.getItem('FoxRest-Authenticate');

    if (isLogin) next();
    else location.href = '/login.html';
}

const routes = [
    // 홈
    {
        path: '/',
        name: 'Home',
        component: () => import('@/router/views/Home'), // 동적 import
        beforeEnter,
        children: [
            // 시스템 > 권한관리 > 메뉴관리
            {
                path: '/PCM_SM0200E',
                name: 'PCM_SM0200E',
                component: () => import('@/router/views/system/PCM_SM0200E'),
                beforeEnter,
            },
            // 시스템 > 권한관리 > 권한그룹관리
            {
                path: '/PCM_SM0400E',
                name: 'PCM_SM0400E',
                component: () => import('@/router/views/system/PCM_SM0400E'),
                beforeEnter,
            },
            // 시스템 > 권한관리 > 사용자관리
            {
                path: '/PCM_SM0310E',
                name: 'PCM_SM0310E',
                component: () => import('@/router/views/system/PCM_SM0310E'),
                beforeEnter,
            },
            // 시스템 > 기타관리 > 코드관리
            {
                path: '/PCM_SM0620E',
                name: 'PCM_SM0620E',
                component: () => import('@/router/views/system/PCM_SM0620E'),
                beforeEnter,
            },
            // 시스템 > 기타관리 > 메인이미지관리
            {
                path: '/PCM_SM0640E',
                name: 'PCM_SM0640E',
                component: () => import('@/router/views/system/PCM_SM0640E'),
                beforeEnter,
            },
            // 시스템 > 기타관리 > 팝업관리
            {
                path: '/PCM_SM0650E',
                name: 'PCM_SM0650E',
                component: () => import('@/router/views/system/PCM_SM0650E'),
                beforeEnter,
            },
            // 시스템 > 기타관리 > 팝업관리 > 팝업관리 상세
            {
                path: '/PCM_SM0651E',
                name: 'PCM_SM0651E',
                component: () => import('@/router/views/system/PCM_SM0651E'),
                beforeEnter,
            },
            // 시스템 > 기타관리 > 배너관리
            {
                path: '/PCM_SM0660E',
                name: 'PCM_SM0660E',
                component: () => import('@/router/views/system/PCM_SM0660E'),
                beforeEnter,
            },
            // 시스템 > 게시판관리 > 게시판 관리
            {
                path: '/PCM_SM0610E',
                name: 'PCM_SM0610E',
                component: () => import('@/router/views/system/PCM_SM0610E'),
                beforeEnter,
            },
            // 시스템 > 게시판관리 > 파일용량모니터
            {
                path: '/PCM_SM0630E',
                name: 'PCM_SM0630E',
                component: () => import('@/router/views/system/PCM_SM0630E'),
                beforeEnter,
            },
            // 사이트 > 코레이트관리 > 공지사항
            {
                path: '/PCM_SM0810R', name: 'PCM_SM0810R',
                component: () => import('@/router/views/site/PCM_SM0810R'),
                beforeEnter,
            },
            // [check-this] 1 page multi use... gate page...
            // {
            //     path: '/PCM_SM0000T', name: 'PCM_SM0000T',
            //     component: () => import('@/router/views/site/PCM_SM0000T'),
            //     beforeEnter,
            // },
            // 사이트 > 코레이트관리 > 공지사항 > 공지사항 상세
            {
                path: '/PCM_SM0811D', name: 'PCM_SM0811D',
                component: () => import('@/router/views/site/PCM_SM0811D'),
                props: true,
                beforeEnter,
            },
            {
                path: '/PCM_SM0812E', name: 'PCM_SM0812E',
                component: () => import('@/router/views/site/PCM_SM0812E'),
                props: true,
                beforeEnter,
            },
            // 사이트 > 코레이트관리 > 개인정보위탁현황
            {
                path: '/PCM_SM0813R',
                name: 'PCM_SM0813R',
                component: () => import('@/router/views/site/PCM_SM0813R'),
            },
            // 사이트 > 코레이트관리 > 포트폴리오
            {
                path: '/PCM_SM0901E',
                name: 'PCM_SM0901E',
                component: () => import('@/router/views/site/PCM_SM0901E'),
            },
            // 연수 관리자 > 임시 계정관리
            {
                path: '/AccountIndex',
                name: 'AccountIndex',
                component: () => import('@/router/views/site-training/AccountIndex'),
                beforeEnter,
            },
            // 연수 관리자 > 그룹관리
            {
                path: '/BoardGroupIndex',
                name: 'BoardGroupIndex',
                component: () => import('@/router/views/site-training/BoardGroupIndex'),
                beforeEnter,
            },
            // 연수 관리자 > 그룹 사용자 관리
            {
                path: '/GroupUserManage',
                name: 'GroupUserManage',
                component: () => import('@/router/views/site-training/GroupUserManage'),
                beforeEnter,
            },
            // 연수 관리자 > 게시판 관리
            {
                path: '/BoardAuthority',
                name: 'BoardAuthority',
                component: () => import('@/router/views/site-training/BoardAuthority'),
                beforeEnter,
            },
            // 연수 관리자 > 게시판 권한 관리
            {
                path: '/BoardManage',
                name: 'BoardManage',
                component: () => import('@/router/views/site-training/BoardManage'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 사용자관리
            {
                path: '/CIM_CM0130E',
                name: 'CIM_CM0130E',
                component: () => import('@/router/views/cims/CIM_CM0130E'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 사용자관리 상세
            {
                path: '/CIM_CM0131E',
                name: 'CIM_CM0131E',
                component: () => import('@/router/views/cims/CIM_CM0131E'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 사업노출설정
            {
                path: '/CIM_CM0301E',
                name: 'CIM_CM0301E',
                component: () => import('@/router/views/cims/CIM_CM0301E'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 공지사항
            {
                path: '/CIM_CM0120R',
                name: 'CIM_CM0120R',
                component: () => import('@/router/views/cims/CIM_CM0120R'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 공지사항 상세
            {
                path: '/CIM_CM0121D',
                name: 'CIM_CM0121D',
                component: () => import('@/router/views/cims/CIM_CM0121D'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 공지사항 수정
            {
                path: '/CIM_CM0122E',
                name: 'CIM_CM0122E',
                component: () => import('@/router/views/cims/CIM_CM0122E'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 자료실
            {
                path: '/CIM_CM0100R',
                name: 'CIM_CM0100R',
                component: () => import('@/router/views/cims/CIM_CM0100R'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 자료실 상세
            {
                path: '/CIM_CM0101D',
                name: 'CIM_CM0101D',
                component: () => import('@/router/views/cims/CIM_CM0101D'),
                beforeEnter,
            },
            // CIMS > CIMS관리 > 자료실 수정
            {
                path: '/CIM_CM0102E',
                name: 'CIM_CM0102E',
                component: () => import('@/router/views/cims/CIM_CM0102E'),
                beforeEnter,
            },
            // 채용 > 채용정보관리 > 입사지원서 양식관리 목록
            {
                path: '/REC_RC0100R',
                name: 'REC_RC0100R',
                component: () => import('@/router/views/recruit/REC_RC0100R'),
                beforeEnter,
            },
            // 채용 > 채용정보관리 > 입사지원서 양식관리 상세
            {
                path: '/REC_RC0110D',
                name: 'REC_RC0110D',
                component: () => import('@/router/views/recruit/REC_RC0110D'),
                beforeEnter,
            },
            // 채용 > 입사지원서 미리보기
            {
                path: '/REC_RC0120D',
                name: 'REC_RC0120D',
                component: () => import('@/router/views/recruit/REC_RC0120D'),
                beforeEnter,
            },
            // 채용 > 모집지원분야/전형절차 관리
            {
                path: '/REC_RC0200D',
                name: 'REC_RC0200D',
                component: () => import('@/router/views/recruit/REC_RC0200D'),
                beforeEnter,
            },
            // 채용 > 지원자관리
            {
                path: '/REC_RC0300R',
                name: 'REC_RC0300R',
                component: () => import('@/router/views/recruit/REC_RC0300R'),
                beforeEnter,
            },
            // 채용 > 지원자관리 > 입사지원서 상세보기
            {
                path: '/REC_RC0310D',
                name: 'REC_RC0310D',
                component: () => import('@/router/views/recruit/REC_RC0310D'),
                beforeEnter,
            },
            // 채용 > 합격자 관리
            {
                path: '/REC_RC0400R',
                name: 'REC_RC0400R',
                component: () => import('@/router/views/recruit/REC_RC0400R'),
                beforeEnter,
            },
            // 채용 > 합격자 관리 > 하위정보
            {
                path: '/REC_RC0410D',
                name: 'REC_RC0410D',
                component: () => import('@/router/views/recruit/REC_RC0410D'),
                beforeEnter,
            },
            // 채용 > 합격자 안내문구 미리보기
            {
                path: '/REC_RC0500R',
                name: 'REC_RC0500R',
                component: () => import('@/router/views/recruit/REC_RC0500R'),
                beforeEnter,
            },
            // 채용 > 안내 템플릿 관리
            {
                path: '/REC_RC0600E',
                name: 'REC_RC0600E',
                component: () => import('@/router/views/recruit/REC_RC0600E'),
                beforeEnter,
            },
            // 미담당민원 > 미담당민원관리 > 미담당지정
            {
                path: '/PCM_SM0710E',
                name: 'PCM_SM0710E',
                component: () => import('@/router/views/system/PCM_SM0710E'),
                beforeEnter,
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;